import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import './Dialog.scss';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { email_informal_href_mailto_crypted } from '../constants';
import { linkTo_UnCryptMailto } from '../helper/unCryptMail';

function Dialog(props) {
    const defaultIndexValue = 0
    const [index, setIndex] = useState(defaultIndexValue);

    const { onClose, items, show } = props;
    const { title, body } = items[index];

    useEffect(() => {
        setIndex(props.defaultIndex);
    }, [props.defaultIndex]);

    const keyListenersMap = new Map([[27, onClose]]);
    useEffect(() => {
        function keyListener(e) {
            const listener = keyListenersMap.get(e.keyCode);
            return listener && listener(e);
        }
        document.addEventListener("keydown", keyListener);

        return () => document.removeEventListener("keydown", keyListener);
    });


    return show === true ? (
        <div className="modal-dialog-gateway">
            <div className="modal-dialog-backdrop" onClick={onClose} />
            <div className="modal-dialog">
                <div className="close" onClick={onClose} />
                <div className="modal-dialog-content-wrapper">
                    <h3 className="title">{title}</h3>
                    <ReactMarkdown className="body">{body}</ReactMarkdown>
                    <button onClick={() => { linkTo_UnCryptMailto(email_informal_href_mailto_crypted); }}>
                        <FontAwesomeIcon icon={faRocket} alt="" role="presentation" />Kontakt aufnehmen
                    </button>
                </div>
            </div>
        </div>
    ) : <React.Fragment />;
}

export default Dialog;