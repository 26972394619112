import React, { useState } from 'react';
import './index.scss';
// import { faXing } from '@fortawesome/free-brands-svg-icons';
// import { ReactComponent as AppleLogo } from '../assets/logos/Apple_logo_black.svg';
import DevicesLogo from '../assets/logos/devices.svg';
import ReactLogo from '../assets/logos/React-icon_1.svg';
import DevopsLogo from '../assets/logos/noun_devops_2606577.svg';
import Dialog from '../components/Dialog';
import leistungenItems from '../assets/texts/leistungenItems';
import skillTags from '../assets/texts/skillTags';
import Contact from '../components/Contact';
import { trackGoogleAnalyticsEvent } from '../helper/GATrackerHelper';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

function LandingPage({ location }) {
    const [showDialog, setShowDialog] = useState(false);
    const [clickedLeistungenIndex, setClickedLeistungenIndex] = useState(0);

    const closeDialog = () => {
        setShowDialog(false);
    };
    const clickOnLeistungenCard = (index) => {
        setClickedLeistungenIndex(index);
        setShowDialog(true);

        trackGoogleAnalyticsEvent("Displayed Leistungen-Modal", {
            "event_category": "Leistungen",
            "event_label": `${leistungenItems[index].slug}`
        })
    };

    return (
        <Layout location={location} mainElementClassname="landing-page" headerClassname='variant-landing-page'>
            <SEO 
                skipTemplate 
                title="Kishor Rana • App Entwicklung" 
                description="Mein Name ist Kishor Rana. Als Software-Entwickler biete ich professionelle
                    Dienstleistungen rund um die App-Entwicklung an."
            />
            <section className="hero">
                <div className="portrait" />
                <div className="intro">Hallo, ich bin Kishor</div>
                <div className="tagline">Dev | iOS | React | CI/CD</div>
                {/* <div className="icons-social">
            <a target="_blank" href="https://github.com/kshrana"><i className="fab fa-github"></i></a>
          </div> */}
            </section>
            <section className="content-wrapper">
                <section className="leistungen">
                    <h2>Leistungen</h2>
                    <div className="line homepage-line" />
                    <div className="leistungen-card-container">
                        <button className="leistungen-card shadow-hover button" onClick={() => { clickOnLeistungenCard(0) }}>
                            <div className="content-wrapper">
                                <div className="icon"><DevicesLogo className="img-devices" role="presentation" fill="#FAFAFA" /></div>
                                <div className="text">
                                    <div className="title">{leistungenItems[0].title}</div>
                                </div>
                            </div>
                        </button>
                        <button className="leistungen-card shadow-hover button" onClick={() => { clickOnLeistungenCard(1) }}>
                            <div className="content-wrapper">
                                <div className="icon"><ReactLogo className="img-react" role="presentation" fill="#FAFAFA" /></div>
                                <div className="text">
                                    <div className="title">{leistungenItems[1].title}</div>
                                </div>
                            </div>
                        </button>
                        <button className="leistungen-card shadow-hover button" onClick={() => { clickOnLeistungenCard(2) }}>
                            <div className="content-wrapper">
                                <div className="icon"><DevopsLogo className="img-devops" role="presentation" fill="#FAFAFA" viewBox="0 0 50 36.2" /></div>
                                <div className="text">
                                    <div className="title">{leistungenItems[2].title}</div>
                                </div>
                            </div>
                        </button>
                    </div>
                </section>

                <section className="skills">
                    <h2>Skills</h2>
                    <div className="line homepage-line" />
                    <div className="chip-container">
                        {skillTags.map((skillTag, index) => (
                            <div key={index} className="chip-root">
                                <div className="chip-left-accessory-view" />
                                <div className="chip-label">
                                    {skillTag}
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="profil">
                    <h2>Über mich</h2>
                    <div className="line homepage-line" />
                    <p className="claim">App-Entwicklung aus Leidenschaft</p>
                    <h4>Erfahrung</h4>
                    <p>Seit über 5 Jahren bin ich professionell in der iOS-App-Entwicklung tätig. So begleitete ich bereits mehrere Apps von der Konzeption über die Entwicklung bis hin zum ersten Release. Dabei war ich einige Jahre im Startup-Umfeld, zuletzt aber auch mehrjährig im Enterprise-Umfeld tätig. Ich arbeitete sowohl im Team im agilen Prozess nach SCRUM als auch als einzelner Spezialist.</p>
                    <h4>Kompetenz</h4>
                    <p>Auszeichnend für mich als Entwickler, ist meine sorgfältige und strukturierte Arbeitsweise und die Fähigkeit kritisch zu hinterfragen. Ich lege erst los, wenn ich alles verstanden habe. Dabei mache ich mir mein umfassendes Skill-Paket in allen Bereichen der App-Entwicklung zu Nutze. Meine Faszination erstreckt sich jedoch nicht nur auf die Programmierung. Ich bin begeistert, wenn professionelle, strukturierte Arbeit auf inspirierende Oberflächen und gute Benutzbarkeit trifft. Für mich stehen daher Design und Usability ebenfalls im Fokus.</p>
                    <div className="statement">
                        Für mich muss Software nicht nur funktionieren, sondern auch intuitiv bedienbar sein!
                            </div>
                    <p className="call-to-action">Sind Sie auf der Suche nach einem erfahrenen und passionierten App-Entwickler, dann freue ich mich auf Ihre Kontaktaufnahme!</p>
                </section>

                <section className="kontakt">
                    <h2>Kontakt</h2>
                    <div className="line homepage-line" />
                    <Contact />
                </section>
            </section>

            <Dialog
                defaultIndex={clickedLeistungenIndex}
                onClose={closeDialog}
                items={leistungenItems}
                show={showDialog}
            />
        </Layout>
    );
}

export default LandingPage;
