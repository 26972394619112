const skillTags = [
    'mobile-app',
    'web-app',

    'ios',
    'swift',
    'objective-c',
    'js',

    'react-js',
    'redux',

    'cocoa-touch',
    'cocoapods',

    'rest',
    'realm-db',
    'sqlite',
    'firebase',
    'fastlane',
    'jenkins',
    'devops',
    'gitlab-ci',
    'buddybuild',
    'jira',
];

export default skillTags;
