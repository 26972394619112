const leistungenItems = [
    {
        slug: "ios-app-entwicklung",
        title: "iOS-App-Entwicklung",
        body: "_Profitieren Sie von Apples App-Plattform & meiner langjährigen Erfahrung in der Nativen iOS-App-Entwicklung._\n\nSeit über 5 Jahren beschäftige ich mich professionell und leidenschaftlich mit der iOS-App-Entwicklung. Ich gebe es gerne zu: Ich bin ein richtiger Apple-Fanboy.\n\nEin großer Vorteil, den Apples Plattform bietet, ist die Fülle an stimmig konzipierten Systemkomponenten, die sich für verschiedenste Apps wiederverwenden lassen. Sie sorgen über die gesamte App-Landschaft für ein nutzerzentriertes und reibungsloses Look-and-Feel und lassen sich jederzeit an Ihre Marke anpassen. Sicher auch aus diesem Grund gilt Apples Plattform & App-Landschaft seit Jahren als die Plattform mit der höchsten Kundenzufriedenheit.\n\n\n\n_Machen Sie sich dies zum Vorteil und profitieren Sie von Apps, die mit hoher Nutzerakzeptanz punkten und einem besonders stabilen Produkt._\n\nGerne bin ich Ihnen dabei behilflich, ich freue mich über Ihre Kontaktaufnahme!"
    },
    {
        slug: "react-webapp-entwicklung",
        title: "React-Webapp-Entwicklung",
        body: "_Profitieren Sie von Anwendungen, die sich unkompliziert in Ihre Systemlandschaft integrieren lassen._  \n  \nWebapps sind überall. Und das aus gutem Grund. Seit Jahren bieten alle Browser eine durchgehend gute Unterstützung für Webapps an. Sie bringen damit viele Vorteile mit sich, denn Webapps sind für jedes Gerät und in jeder Umgebung aufrufbar – ob vom PC, Tablet oder Handy – ob im öffentlichen Internet für jedermann oder als unternehmens-interne Lösung lediglich im Intranet. Anwender brauchen nichts zu installieren. Ein Aufruf entsprechend einer herkömmlichen Website genügt.  \n  \n_Machen Sie sich dies zum Vorteil und profitieren Sie von maßgeschneiderten Lösungen, die sich flexibel einsetzen lassen und die Effizienz Ihrer Prozesse erhöhen._  \n  \nGerne bin ich Ihnen dabei behilflich, ich freue mich über Ihre Kontaktaufnahme!"
    },
    {
        slug: "ci-cd",
        title: "Automatisierte Prozesse",
        body: "_Profitieren Sie von einem smarten Entwicklungsprozess, der Kosten & Zeit einspart und damit schlanke Lösungen ermöglicht._  \n  \nDie Einrichtung von automatisierten Prozessen im Sinne der Continuous Integration & Continuous Deployment (CI/CD) empfiehlt sich als unterstützende Maßnahme für jedes Entwicklungsprojekt. Sie ermöglichen repetitive und zeitaufwändige Hintergrundarbeiten, die für jedes Software-Projekt anfallen, zu automatisieren. Hierbei kommt Standardsoftware zum Einsatz, die zu Beginn eines Projektes konfiguriert und lediglich an projektspezifische Anforderungen angepasst wird.  \n  \nSie profitieren von einem stabilen Produkt, bei dem Sie ohne Mehraufwände jederzeit den aktuellen Entwicklungsstand einsehen können − live und unkompliziert. Schnelles Feedback stellt einen schlanken und damit effizienten Entwicklungsprozess sicher. Die Systeme werden zudem auf das Zusammenspiel unterschiedlicher Komponenten verifiziert. Außerdem lassen sich bei einem bestehenden CI/CD-System weitere Testsysteme einbinden, die eine reibungslose Nutzung Ihres Produktes zu jeder Zeit sicherstellen."
    }
];

export default leistungenItems;